import React, { useContext, useState } from "react";
import { SearchContext } from "../context";
import { ReactComponent as WaitingIcon } from "../waiting.svg";
import { ReactComponent as CloseIcon } from "../cancel.svg";

const DEBUG_MODE = false;

const TEST_DATA = {
  vehiculo: {
    placa: "M609BYP",
    marca: "Fengchi",
    color: "Rojo",
    tipo: "Moto",
    tarjeta: null,
  },
  multas: [
    {
      id: "2",
      serie: "A",
      numero: "25081",
      fecha: "21-08-2020",
      lugar: "Calle Transito Rojas Y 2da Av Zona 2",
      monto: "400.00",
      infractor: "Cesar Orlando Estrada Y Estrada",
      montoPagar: "415.78",
      descuento: "0",
      impuesto: "15.78"
    },
  ],
};

const SearchForm = ({ displaySearch, setDisplaySearch, setShowResult }) => {
  const [waiting, setWaiting] = useState(false);
  const { setResultSet, setCurrentPlaca } = useContext(SearchContext);
  const focusInput = (component) => {
    if (component) {
      component.focus();
    }
  };

  return (
    <section className={displaySearch ? "search search--open" : "search"}>
      <div className="search__inner search__inner--up">
        <button
          id="btn-search-close"
          className="btn btn--search-close"
          aria-label="Cancelar consulta de remision"
          onClick={() => setDisplaySearch(false)}
        >
          <CloseIcon className="icon" />
        </button>
        <form className="search__form" action="">
          <div className="input-wrapper">
            <input
              ref={focusInput}
              className="search__input"
              name="search"
              type="search"
              placeholder="Buscar Placa"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onKeyDown={(event) => {
                event.stopPropagation();
                if (event.keyCode === 13) {
                  if (!DEBUG_MODE) {
                    const inputValue = event.target.value.trim();
                    if (inputValue) {
                      setWaiting(true);
                      setCurrentPlaca(inputValue.toUpperCase());
                      const formData = new FormData();
                      formData.append("placa", inputValue);
                      fetch(
                        "/wp-content/plugins/widget-buscar-multas/includes/multas.php",
                        {
                          method: "POST",
                          body: formData,
                        }
                      )
                        .then((response) => {
                          setWaiting(false);
                          event.target.value = "";
                          return response.json();
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                          setWaiting(false);
                        })
                        .then((data) => {
                          setResultSet(data);
                          setShowResult(true);
                          setDisplaySearch(false);
                        });
                    }
                  } else {
                    setResultSet(TEST_DATA);
                    setShowResult(true);
                    setDisplaySearch(false);
                  }
                  event.preventDefault();
                }

                if (event.keyCode === 27) {
                  setDisplaySearch(false);
                  event.preventDefault();
                }
              }}
            />
            {waiting && (
              <div className="loading-wrapper">
                <span className="waiting-icon">
                  <WaitingIcon className="icon" />
                </span>
              </div>
            )}
          </div>
          <span className="search__info">
            Presiona Enter Para Buscar o ESC para salir
          </span>
        </form>
      </div>
      <div className="search__inner search__inner--down">
        <div className="search__related">
          <div className="search__suggestion">
            <h3>Descuento Multa</h3>
            <p>
              Una multa impuesta por un policía de tránsito municipal, se
              cancela dentro de los cinco días hábiles siguientes a su
              imposición, el infractor tendrá derecho a un descuento del
              veinticinco por ciento deducido del monto total de la multa.
            </p>
          </div>
          <div className="search__suggestion">
            <h3>Impuesto Multa</h3>
            <p>
              A partir del sexto día hábil, posterior a la imposición de la
              multa, el infractor pagará el monto completo de la multa más
              intereses por mora calculados al veinte por ciento anual.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchForm;
