import React, { useState } from "react";
import "./SuperResponsiveTableStyle.css";
import { ReactComponent as SearchIcon } from "./searchingcar.svg";
import "./App.css";
import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";
import { SearchContextProvider } from "./context";

const App = () => {
  const [displaySearch, setDisplaySearch] = useState(false);
  const [showResult, setShowResult] = useState(false);

  return (
    <main className="app">
      <SearchContextProvider resultSet={{}}>
        <div
          className={displaySearch ? "main-wrap main-wrap--hide" : "main-wrap"}
        >
          <header className="app-header">
            <h1 className="app-header__title">CONSULTA REMISIONES</h1>
            <div className="search-wrap">
              <button
                id="btn-search"
                className="btn btn--full btn--search"
                title="Buscar Placa"
                onClick={() => setDisplaySearch(!displaySearch)}
              >
                <SearchIcon className="icon" />
                <span>BUSCAR</span>
              </button>
            </div>
          </header>
          <section className="app-body">
            <div className="content-wrapper">
              <div className="content">
                {showResult && <SearchResult />}
              </div>
            </div>
          </section>
          <footer className="app-footer">
            <span className="powerby">
              © {new Date().getFullYear()} Powered by
              {` `}
            </span>
            <a
              className="vendorlink"
              href="https://tio-gt.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="TIOgt Una empresa de desarrollo de software de Zacapa, Guatemala"
            >
              www.tio-gt.com
            </a>
          </footer>
        </div>
        <SearchForm
          displaySearch={displaySearch}
          setDisplaySearch={setDisplaySearch}
          setShowResult={setShowResult}
        />
      </SearchContextProvider>
    </main>
  );
};

export default App;
