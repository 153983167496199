import React, { createContext, useState } from "react";

export const Context = createContext({});

export const Provider = (props) => {
  const { resultSet: initialResultSet, children } = props;

  const [resultSet, setResultSet] = useState(initialResultSet);
  const [currentPlaca, setCurrentPlaca] = useState(initialResultSet);

  const searchContext = {
    resultSet,
    setResultSet,
    currentPlaca,
    setCurrentPlaca,
  };

  return <Context.Provider value={searchContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
