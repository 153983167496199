import React, { useContext } from "react";
import { SearchContext } from "../context";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { ReactComponent as PrintIcon } from "../printing.svg";

const SearchResult = () => {
  const {
    resultSet: { vehiculo, multas },
    currentPlaca,
  } = useContext(SearchContext);

  return (
    <>
      {vehiculo.placa ? (
        <div className="search-result">
          <div className="headings">
            <div className="heading-with-print">
              <h2>Información del Vehículo</h2>
              <div className="btn-print-wrapper">
                <button
                  className="btn btn--print"
                  aria-label="Imprimir Remisiones"
                  onClick={() => window.print()}
                >
                  <PrintIcon className="icon" />
                </button>
              </div>
            </div>
            <div className="line-separator"></div>
          </div>

          <Table className="table">
            <Thead>
              <Tr>
                <Th>PLACA</Th>
                <Th>TIPO VEHÍCULO</Th>
                <Th>MARCA</Th>
                <Th>COLOR</Th>
                <Th>TARJETA DE CIRCULACIÓN</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{vehiculo.placa}</Td>
                <Td>{vehiculo.tipo}</Td>
                <Td>{vehiculo.marca}</Td>
                <Td>{vehiculo.color}</Td>
                <Td>{vehiculo.tarjeta}</Td>
              </Tr>
            </Tbody>
          </Table>

          <div className="headings">
            <h2>Detalle de Remisiones</h2>
            <div className="line-separator"></div>
          </div>
          <Table className="table">
            <Thead>
              <Tr>
                <Th>SERIE</Th>
                <Th>NO.</Th>
                <Th>FECHA</Th>
                <Th>INFRACTOR</Th>
                <Th>LUGAR</Th>
                <Th>MONTO</Th>
                <Th>DESCUENTO</Th>
                <Th>IMPUESTO</Th>
                <Th>TOTAL</Th>
              </Tr>
            </Thead>
            <Tbody>
              {multas.map((item, idx) => (
                <Tr key={idx}>
                  <Td>{item.serie}</Td>
                  <Td>{item.numero}</Td>
                  <Td>{item.fecha}</Td>
                  <Td>{item.infractor || 'Ausente'}</Td>
                  <Td>{item.lugar}</Td>
                  <Td>{item.monto}</Td>
                  <Td>{item.descuento}</Td>
                  <Td>{item.impuesto}</Td>
                  <Td>{item.montoPagar}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <div className="search__leyenda">
            <h3>Información:</h3>
            <p>
               Las remisiones ó multas pueden ser canceladas en caja de la Tesorería Municipal de Jalapa.
            </p>
          </div>
        </div>
      ) : (
        <div className="search-no-result">
          <div>¡Eres un ciudadano ejemplar!</div>
          <div>
            Tú vehículo con placa No. <span>{currentPlaca}</span> NO posee
            remisión.
          </div>
        </div>
      )}
    </>
  );
};

export default SearchResult;
